import React from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import Hero from "./Hero";
import Preesale from "./Preesale";
import HowtoJoin from "./HowtoJoin";
import Phase from "./Phase";
import Table from "./Table";

function BuyBox() {
  return (
    <>
      <Header />
      <Hero />
      <Preesale />
      <Phase />
      <HowtoJoin />

      <Footer />
    </>
  );
}

export default BuyBox;
